import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

const Wrapper = styled.div`
    padding-bottom: 16px;
`;

const Title = styled.div`
    padding: 10px 24px;
    border-radius: 50px;
    background-color: ${props => props.theme.global.accent};
    color: ${props => props.theme.global.altFontColor};
    font-size: 1.2rem;
    text-align: left;
    width: 200px;
`;

const PillWrapper = styled.div`
    padding-left: 50px;
    display: flex;
    margin: 16px 0;
    flex-wrap: wrap;
`;

const SkillPill = styled.div`
    padding: 8px 16px;
    border-radius: 50px;
    width: fit-content;
    background-color: ${props => props.theme.global.main};
    color: ${props => props.theme.global.altFontColor};
    font-size: 0.95rem;
    text-align: left;
    margin-right: 16px;
    white-space: nowrap;
    margin-bottom: 16px;
`;

const SkillsList = props => {
    const { themes, current, skill } = props;

    const renderPills = () => {
        return skill.list.map(pill => {
            return (
                <SkillPill theme={themes[current]} key={pill}>
                    {pill}
                </SkillPill>
            );
        });
    };

    const renderSkill = () => {
        return (
            <Wrapper>
                <Title theme={themes[current]}>{skill.title}</Title>
                <PillWrapper>{renderPills()}</PillWrapper>
            </Wrapper>
        );
    };

    return renderSkill();
};

const mapStateToProps = state => ({
    themes: state.themes,
    current: state.currentTheme,
});

export default connect(mapStateToProps)(SkillsList);
