import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ModalView from '../views/ModalView';
import ImageViewer from '../views/modalViews/ImageViewer';

const Wrapper = styled.div`
    border-radius: 16px;
    background-color: ${props => props.theme.global.transparentBg};
    display: flex;
    padding: 16px;
    color: black;
    padding: 16px;
    margin-bottom: 24px;

    div:nth-child(1) {
        flex: 1 1 15%;
    }
    div:nth-child(2) {
        padding: 0 16px;
        flex: 1 1 40%;
        justify-content: center;
    }
    div:nth-child(3) {
        flex: 1 1 40%;
    }
`;

const ColumnContent = styled.div`
    display: flex;
    color: ${props => props.theme.global.fontColor};
    line-height: 1.9;
`;

const LogoImgWrapper = styled.div`
    width: 100%;
    img {
        width: ${props => (props.small ? '100px' : '250px')};
        height: auto;
        max-width: 100%;
        object-fit: cover;
    }
`;

const ImgWrapper = styled.div`
    display: flex;
    /* height: 200px; */
    flex-wrap: wrap;
    justify-content: space-around;
    img {
        padding-bottom: 8px;
        /* width: 200px; */
        height: 200px;
        max-width: 100%;
        object-fit: cover;
        cursor: pointer;
    }
`;

const PortfolioCard = props => {
    const { themes, current, card } = props;

    const [imageViewer, setImageVeiwer] = useState(false);
    const [selectedImg, setSelectedImg] = useState(null);

    const openImage = img => {
        setSelectedImg(img);
        setImageVeiwer(true);
    };
    const closeImageViewer = () => {
        setImageVeiwer(false);
        setSelectedImg(false);
    };

    const renderImagerViewer = () => (
        <ImageViewer imgae={selectedImg} close={closeImageViewer} />
    );

    const renderImages = () => {
        return card.images.map(imgItem => {
            return (
                <img
                    src={imgItem}
                    onClick={() => openImage(imgItem)}
                />
            );
        });
    };

    return (
        <React.Fragment>
            {imageViewer && (
                <ModalView
                    view={renderImagerViewer}
                    close={closeImageViewer}
                />
            )}
            <Wrapper theme={themes[current]}>
                <ColumnContent theme={themes[current]}>
                    <LogoImgWrapper small={card.size}>
                        <img
                            src={
                                current === 'dark'
                                    ? card.altLogo
                                    : card.logo
                            }
                            alt={card.altText}
                        />
                    </LogoImgWrapper>
                </ColumnContent>
                <ColumnContent theme={themes[current]}>
                    <ImgWrapper>{renderImages()}</ImgWrapper>
                </ColumnContent>
                <ColumnContent theme={themes[current]}>
                    {card.desc}
                </ColumnContent>
            </Wrapper>
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    themes: state.themes,
    current: state.currentTheme,
});

export default connect(mapStateToProps)(PortfolioCard);
