import React from 'react';
import { connect } from 'react-redux';
import ReactDom from 'react-dom';
import styled from 'styled-components';

const modalRoot = document.getElementById('modal-root');

const Cover = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    height: 100vh;
    width: 100vw;
    z-index: ${props => (props.sitOnTop ? 10 : 0)};
    cursor: pointer;
`;

const Window = styled.div`
    position: absolute;
    background: ${props => props.theme.global.bg};
    z-index: ${props => (props.sitOnTop ? 11 : 1)};
    transform: translate(-50%, -50%);
    top: 50vh;
    left: 50vw;
    border-radius: 10px;
    color: ${props => props.theme.global.fontColor};
    overflow-y: scroll;
`;

const ModalView = props => {
    const { view, close, themes, currentTheme, sitOnTop } = props;

    const showModal = () => {
        return (
            <React.Fragment>
                <Window
                    className="scrollBar"
                    theme={themes[currentTheme]}
                    sitOnTop={sitOnTop}
                >
                    {view()}
                </Window>
                <Cover
                    onClick={() => close()}
                    sitOnTop={sitOnTop}
                ></Cover>
            </React.Fragment>
        );
    };

    return ReactDom.createPortal(showModal(), modalRoot);
};

const mapStateToProps = state => ({
    themes: state.themes,
    currentTheme: state.currentTheme,
});

export default connect(mapStateToProps)(ModalView);
