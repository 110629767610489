import initState from './initialState';

export default function appReducer(state = initState, action) {
    switch (action.type) {
        case 'UPDATE_THEMES':
            return {
                ...state,
                themes: action.payload,
            };

        case 'UPDATE_CURRENT_THEME':
            return {
                ...state,
                currentTheme: action.payload,
            };

        case 'UPDATE_NAV':
            return {
                ...state,
                currentNav: action.payload,
            };

        default:
            return state;
    }
}
