import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import AndrewKasper from '../assets/img/AndrewKasper.png';
import SkillsList from '../componentes/SkillsList';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ImgWrapper = styled.div`
    width: 33%;
    position: relative;
    z-index: 0;
    img {
        height: 100vh;
        padding: 0;
        opacity: ${props => props.theme.global.imgOpacity};
    }
`;

const Title = styled.div`
    font-size: 8rem;
    > div {
        font-size: 4rem;
    }
`;

const MainContentWrapper = styled.div`
    padding: 16px 24px;
    text-align: right;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
    position: relative;
    z-index: 2;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 36vw;
    float: right;
    white-space: normal;
    position: absolute;
    right: 0;
`;

const Content = styled.div`
    text-align: left;
    padding: 24px;
    background-color: ${props => props.theme.global.transparentBg};
    border-radius: 16px;
    margin-bottom: 16px;
    line-height: 1.5;
`;

const HomeView = props => {
    const { themes, current } = props;

    const skills = [
        {
            id: 'sk1',
            title: 'UX / UI',
            list: [
                'Illistrator',
                'Figma',
                'Balsamic',
                'Mural',
                'User stories',
                'User Personas',
                'Responsive Design',
            ],
        },
        {
            id: 'sk2',
            title: 'Javascript',
            list: [
                'React',
                'ReactWindow',
                'Redux',
                'Axios',
                'Vue',
                'Vuex',
                'Vue-Router',
                'Node',
                'D3',
            ],
        },
        {
            id: 'sk3',
            title: 'Python',
            list: ['python', 'FastAPI'],
        },
        {
            id: 'sk4',
            title: 'Database',
            list: ['MySQL', 'PostgreSQL'],
        },
        {
            id: 'sk5',
            title: 'CSS',
            list: ['CSS3', 'Flex Box', 'SCSS', 'LESS'],
        },
        {
            id: 'sk6',
            title: 'HTML',
            list: ['HTML5', 'XHTML'],
        },
    ];

    const renderSkills = () => {
        return skills.map(skill => {
            return <SkillsList key={skill.id} skill={skill} />;
        });
    };

    if (!themes) return null;
    return (
        <Wrapper>
            <ImgWrapper theme={themes[current]}>
                <img src={AndrewKasper} />
            </ImgWrapper>
            <MainContentWrapper>
                <Title>
                    Andrew Kasper
                    <div>Full Stack Engineer</div>
                </Title>
                <ContentWrapper className="scrollBar">
                    <Content theme={themes[current]}>
                        I am a self-taught Full Stack Software
                        Engineer with over 15 years of experience
                        specializing in UX, UI design, and front-end
                        development. Proficient in JavaScript, React,
                        and Redux, with a strong passion for building
                        elegant, maintainable, and high-performance
                        applications. Skilled in driving innovation
                        through collaborative cross-functional work
                        and building scalable, consumer-facing
                        applications.
                    </Content>
                    {renderSkills()}
                </ContentWrapper>
            </MainContentWrapper>
        </Wrapper>
    );
};

const mapStateToProps = state => ({
    themes: state.themes,
    current: state.currentTheme,
});

export default connect(mapStateToProps)(HomeView);
