import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { LinkedIn, EmailIcon } from '../../assets';

const Wrapper = styled.div`
    width: 30vw;
    padding: 16px;
    overflow: hidden;
    margin: 0;
`;

const Title = styled.div`
    font-size: 4rem;
    text-align: center;
`;

const ContactLinks = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;

    svg {
        width: 50px;
        fill: ${props => props.theme.global.fontColor};
    }
`;

const ContactMe = props => {
    const { themes, current } = props;
    return (
        <Wrapper className="scrollBar" theme={themes[current]}>
            <Title>Send me a note!</Title>
            <ContactLinks theme={themes[current]}>
                <a
                    href="https://www.linkedin.com/in/andrewkasper/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <LinkedIn />
                </a>
                <a href="mailto:contact@andrewkasper.com">
                    <EmailIcon />
                </a>
            </ContactLinks>
        </Wrapper>
    );
};

const mapStateToProps = state => ({
    themes: state.themes,
    current: state.currentTheme,
});

export default connect(mapStateToProps)(ContactMe);
