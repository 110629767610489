import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
    AKLogo,
    HomeIcon,
    AboutIcon,
    ContactIcon,
    PortfolioIcon,
    MoonIcon,
    SunIcon,
    ResumeIcon,
} from '../assets';

import ModalView from '../views/ModalView';
import ContactMe from '../views/modalViews/ContactMe';

const Wrapper = styled.div`
    width: 200px;
    height: 100vh;
    background: ${props => props.theme.global.main};
`;

const LogoWrapper = styled.div`
    padding: 16px;
`;

const NavItem = styled.div`
    display: flex;
    align-items: center;
    padding: 8px;
    color: ${props => props.theme.sidebar.fontColor};
    background: ${props =>
        props.nav === props.item
            ? props.theme.global.accent
            : 'transparent'};
    cursor: pointer;

    &:hover {
        background: ${props => props.theme.global.accent};
    }

    svg {
        width: 20px;
        margin-right: 16px;
        padding-left: 8px;
        fill: ${props => props.theme.sidebar.iconColor};
    }
`;

const DarkModeSwitch = styled.label`
    overflow: hidden;
    width: 59px;
    border-radius: 5px;
    border: 1px solid #adadad;
    font-weight: 400;
    display: inline-block;
    max-width: 100%;
    position: absolute;
    bottom: 10px;
    left: 10px;
    cursor: pointer;
    svg {
        height: 10px;
        padding: 0 6px;
    }

    input {
        margin-left: -200%;
        float: left;
        line-height: normal;
    }

    input + div {
        width: 127px;
        transition: all ease-in-out 0.2s;
    }

    input:not(:checked) + div {
        margin-left: -67px;
    }

    input + div div {
        color: #fff;
        background-color: #1e1e1e;
        float: left;
        padding: 2px 14px;
    }

    input + div div:nth-child(2) {
        background: #fff;
        box-shadow: 0px 0px 5px #666 inset;
        border-radius: 5px;
        width: 20px;
        padding: 2px 8px;
        margin-left: -4px;
        margin-right: -4px;
        position: relative;
    }

    input + div div:last-child {
        color: #333;
        background-color: #e6e6e6;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3) inset;
    }
`;

const SideBar = props => {
    const { themes, current, changeTheme, currentNav } = props;

    const [contactView, setContactView] = useState(false);

    const [theme, setTheme] = useState(null);

    const navigation = [
        { id: 1, title: 'Home', icon: <HomeIcon />, nav: 'home' },
        {
            id: 2,
            title: 'About Me',
            icon: <AboutIcon />,
            nav: 'about',
        },
        {
            id: 3,
            title: 'Portfolio',
            icon: <PortfolioIcon />,
            nav: 'protfolio',
        },
        {
            id: 4,
            title: 'Contact',
            icon: <ContactIcon />,
            nav: 'contact',
        },
        {
            id: 5,
            title: 'Resume',
            icon: <ResumeIcon />,
            nav: 'resume',
        },
    ];

    const changeNav = navItem => {
        if (navItem === 'contact') {
            setContactView(true);
        } else if (navItem === 'resume') {
            const pdf = '/data/Andrew-Kasper-Resume.pdf';
            window.open(pdf, '_blank');
        } else {
            props.dispatch({ type: 'UPDATE_NAV', payload: navItem });
        }
    };

    const renderNav = () => {
        return navigation.map(nav => {
            return (
                <NavItem
                    key={nav.id}
                    theme={theme}
                    nav={currentNav}
                    item={nav.nav.toLowerCase()}
                    onClick={() => changeNav(nav.nav)}
                >
                    {nav.icon}
                    {nav.title}
                </NavItem>
            );
        });
    };

    const themeChange = () => {
        if (current === 'dark') {
            changeTheme('light');
            props.dispatch({
                type: 'UPDATE_CURRENT_THEME',
                payload: 'light',
            });
        } else {
            changeTheme('dark');
            props.dispatch({
                type: 'UPDATE_CURRENT_THEME',
                payload: 'dark',
            });
        }
    };

    const renderContactView = () => <ContactMe />;

    const closeContact = () => {
        setContactView(false);
    };

    useEffect(() => {
        if (themes) {
            setTheme(themes[current]);
        }
    }, [themes, current]);

    if (!theme) return null;

    return (
        <React.Fragment>
            {contactView && (
                <ModalView
                    view={renderContactView}
                    close={closeContact}
                />
            )}
            <Wrapper theme={theme}>
                <LogoWrapper>
                    <AKLogo />
                </LogoWrapper>
                {renderNav()}

                <DarkModeSwitch>
                    <input type="checkbox" onChange={themeChange} />
                    <div>
                        <div>
                            <MoonIcon />
                        </div>
                        <div>&nbsp;</div>
                        <div>
                            <SunIcon />
                        </div>
                    </div>
                </DarkModeSwitch>
            </Wrapper>
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    themes: state.themes,
    current: state.currentTheme,
    currentNav: state.currentNav,
});

export default connect(mapStateToProps)(SideBar);
