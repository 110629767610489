import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    height: 90vh;
    width: 90vw;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
        width: auto;
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.6);
    }
`;

const ImageViewer = props => {
    const { imgae, close } = props;

    return (
        <Wrapper onClick={close}>
            <img src={imgae} />
        </Wrapper>
    );
};

export default ImageViewer;
