import React, { useState, useEffect } from 'react';
import './global.css';
import styled from 'styled-components';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import axios from 'axios';
import reducer from './store/reducer';

import ViewController from './views/ViewController';
import SideBar from './componentes/SideBar';

import backgroundImg from './assets/background.svg';

const store = configureStore({ reducer });

const AppWrapper = styled.div`
    padding: 0px;
    margin: 0px;
`;

const BackgroundImg = styled.div`
    background-image: url(${backgroundImg});
    background-color: ${props => props.theme.global.bg};
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    animation: all ease-in-out 0.3s;
`;

function App() {
    const [themes, setThemes] = useState(null);

    const [currentTheme, setCurrentTheme] = useState('light');

    useEffect(() => {
        async function fetchThemes() {
            if (!themes) {
                const response = await axios.get('/data/themes.json');
                setThemes(response.data);
            }
        }
        fetchThemes();
    }, [themes]);

    return (
        <Provider store={store}>
            <AppWrapper className="App">
                {themes && (
                    <BackgroundImg theme={themes[currentTheme]}>
                        <SideBar changeTheme={setCurrentTheme} />
                        <ViewController themes={themes} />
                    </BackgroundImg>
                )}
            </AppWrapper>
        </Provider>
    );
}

export default App;
