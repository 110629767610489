import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import HomeView from './HomeView';
import AboutMe from './AboutMe';
import PortfolioView from './PortfolioView';

const MainWrapper = styled.div`
    padding: 0;
    margin: 0;
    height: 100vh;
    width: calc(100vw - 150px);
    overflow: hidden;
    color: ${props => props.theme.global.fontColor};
`;

const ViewController = props => {
    const { themes, current, nav } = props;

    useEffect(() => {
        if (themes) {
            props.dispatch({
                type: 'UPDATE_THEMES',
                payload: themes,
            });
        }
    }, [themes, props]);

    const renderComponentView = () => {
        switch (nav) {
            case 'home':
                return <HomeView />;

            case 'about':
                return <AboutMe />;

            case 'protfolio':
                return <PortfolioView />;

            default:
                return <HomeView />;
        }
    };

    const renderView = () => {
        if (themes) {
            return (
                <MainWrapper theme={themes[current]}>
                    {renderComponentView()}
                </MainWrapper>
            );
        }
        return null;
    };

    return renderView();
};

const mapStateToProps = state => ({
    current: state.currentTheme,
    nav: state.currentNav,
});

export default connect(mapStateToProps)(ViewController);
