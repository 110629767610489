import React, { useState } from 'react';
import styled from 'styled-components';

import PortfolioCard from '../componentes/PortfolioCard';

import {
    FabcomLogo,
    FabcomLogoDark,
    Fabcom1,
    Fabcom2,
    UATLogo,
    UAT1,
    UAT2,
    UAT3,
    MCHP1,
    MCHP2,
    MCHP3,
    MCHPLogo,
    MCHPLogoDark,
    LabelPicsLogo,
    LabelPicsLogoDark,
    LabelPics1,
    LabelPics2,
    ExplodingGoats1,
    ExplodingGoats2,
    ExplodingGoats3,
    ExplodingGoatsLogo,
    ExplodingGoatsLogoDark,
    CAYGLogo,
    CAYG1,
    CAYG2,
    Base1,
    Base2,
    Base3,
    BaseLogo,
} from '../assets/img';

const Wrapper = styled.div`
    padding: 16px;
`;

const Title = styled.div`
    font-size: 8rem;
    text-align: right;
`;

const MainContent = styled.div`
    height: calc(100vh - 180px);
    overflow-y: scroll;
    overflow-x: hidden;
`;

const PortfolioView = props => {
    const PortfolioItems = [
        {
            id: 'p1',
            altText: 'Close As You Go',
            altLogo: CAYGLogo,
            logo: CAYGLogo,
            size: 'small',
            desc: 'UX/UI Architect for Deloitte’s “Close as You Go” Solution. I had the privilege of leading the UX/UI design and development for Deloitte’s “Close as You Go,” a cutting-edge financial management tool designed to revolutionize public sector financial close processes. Leveraging React and a suite of advanced libraries, I crafted an intuitive, user-friendly interface that enables real-time data accuracy and streamlined financial operations. Additionally, I designed the logo and created custom 3D assets, contributing to a cohesive and visually engaging brand identity. My work not only enhanced user experience but also played a key role in the tool’s success in driving efficiency and transparency.',
            images: [CAYG1, CAYG2],
        },
        {
            id: 'p2',
            altText: 'Admin Panel',
            altLogo: BaseLogo,
            logo: BaseLogo,
            size: 'small',
            desc: 'I designed and developed a comprehensive UX/UI application that integrates with an organizational system, providing real-time visibility of all servers and users within the network. This app empowers administrators to efficiently manage and update user access, while also enabling users to view system logs and monitor their access levels. The intuitive interface and robust functionality of the app ensure seamless administration and enhanced security across the network.',
            images: [Base1, Base2, Base3],
        },
        {
            id: 'p3',
            altText: 'Microchip',
            altLogo: MCHPLogoDark,
            logo: MCHPLogo,
            desc: 'At Microchip.com, I led the transformation of the site from a static platform to a dynamic, modern web application. As the principal architect, I spearheaded the migration to Sitefinity CMS, leveraging the .NET framework with an AngularJS frontend. This project included a comprehensive redesign to enhance the site’s aesthetics and functionality. Over time, the site evolved, and I guided the transition to a custom Vue.js application, significantly improving performance, connectivity, and standardization across the entire platform.',
            images: [MCHP1, MCHP2, MCHP3],
        },
        {
            id: 'p4',
            altText: 'Fabcom',
            logo: FabcomLogo,
            altLogo: FabcomLogoDark,
            desc: 'At FabCom, a leading integrated marketing and advertising agency in Scottsdale, Arizona, I collaborated closely with the art director to develop an innovative website. FabCom is renowned for its cutting-edge approaches like Neuromarketology™ and multi-screen content marketing. Our project focused on creating an engaging, interactive menu system that enhanced user navigation across the site. Built using Expression Engine CMS, HTML5, CSS3, and JavaScript, the website dynamically displayed relevant information, highlighting my ability to blend technical expertise with creative design.',
            images: [Fabcom1, Fabcom2],
        },
        {
            id: 'p5',
            altText: 'UAT',
            logo: UATLogo,
            altLogo: UATLogo,
            size: 'small',
            desc: 'At the University of Advancing Technology, a premier institution dedicated to technology-driven careers, I played a pivotal role in enhancing the user experience on their website. I designed and implemented an interactive carousel on the homepage, allowing users to seamlessly browse through the diverse range of degrees offered. My responsibilities extended to the overall design and development of the site, including the creation of most pages. The site was built using PHP for data management and JavaScript to dynamically retrieve and display information within the HTML structure.',
            images: [UAT1, UAT2, UAT3],
        },
        {
            id: 'p6',
            altText: 'LabelPics',
            logo: LabelPicsLogo,
            altLogo: LabelPicsLogoDark,
            desc: 'Label Pics is a utility app designed specifically for early childhood professionals, enabling teachers to efficiently create custom photo labels for their classrooms. Developed using the Cordova CLI, this application leverages HTML5, CSS3, and JavaScript to deliver a seamless experience across both iOS and Android platforms.',
            images: [LabelPics1, LabelPics2],
        },
        {
            id: 'p7',
            altText: 'Exploding Goats',
            logo: ExplodingGoatsLogo,
            altLogo: ExplodingGoatsLogoDark,
            desc: 'Exploding Goats is an engaging continuous side-scrolling game where players must strategically time their screen taps to synchronize with the bird’s dive, propelling goats into a gully. However, if a goat is chewing on a stick of dynamite when the bird makes contact, the game ends, and the player’s score is tallied. This game was developed entirely in Swift, as part of my self-directed learning initiative to master the language. Exploding Goats served as a proof of concept, demonstrating my ability to successfully create a fully functional mobile application.',
            images: [
                ExplodingGoats1,
                ExplodingGoats2,
                ExplodingGoats3,
            ],
        },
    ];

    const renderCards = () => {
        return PortfolioItems.map(item => {
            return <PortfolioCard key={item.id} card={item} />;
        });
    };

    return (
        <Wrapper>
            <Title>Portfolio</Title>
            <MainContent className="scrollBar">
                {renderCards()}
            </MainContent>
        </Wrapper>
    );
};

export default PortfolioView;
