import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import AndrewKasper from '../assets/img/AndrewKasper.png';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ImgWrapper = styled.div`
    width: 33%;
    position: relative;
    z-index: 0;
    img {
        height: 100vh;
        padding: 0;
        opacity: ${props => props.theme.global.imgOpacity};
    }
`;

const Title = styled.div`
    font-size: 8rem;
`;

const MainContentWrapper = styled.div`
    padding: 16px 24px;
    text-align: right;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
    position: relative;
    z-index: 2;
    width: 50%;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;

    float: right;
    position: absolute;
    right: 0;
`;

const Content = styled.div`
    text-align: left;

    padding: 24px;
    background-color: ${props => props.theme.global.transparentBg};
    border-radius: 16px;
    line-height: 1.9;
`;

const AboutMe = props => {
    const { themes, current } = props;

    return (
        <Wrapper>
            <ImgWrapper theme={themes[current]}>
                <img src={AndrewKasper} />
            </ImgWrapper>
            <MainContentWrapper>
                <Title>About Me</Title>
                <ContentWrapper>
                    <Content theme={themes[current]}>
                        <p>
                            I am a passionate UI/UX web developer with
                            over 15 years of experience in creating
                            rich, user-centered experiences. My
                            journey into the world of software
                            development began unexpectedly while
                            working as a graphic designer at a small
                            landscaping company. Tasked with
                            developing an internal system, I quickly
                            discovered a deep love for coding and
                            problem-solving. This newfound passion led
                            me to immerse myself in learning—watching
                            tutorials, attending seminars, and even
                            diving into technical books.
                        </p>
                        <p>
                            As my career progressed, I transitioned
                            into web development, where I honed my
                            skills in building intuitive,
                            high-performance applications. My focus on
                            UX/UI design has been central to my work,
                            and I am proficient in JavaScript, React,
                            Redux, Angular, and VueJs. I thrive on the
                            challenge of continuously learning and
                            adapting, particularly when it comes to
                            mastering new frameworks and technologies.
                        </p>
                        <p>
                            In addition to my technical expertise, I
                            bring a unique perspective from my
                            background in graphic design and
                            advertising. This blend of design and
                            development skills allows me to foresee
                            potential challenges and create seamless,
                            scalable solutions that enhance user
                            satisfaction.
                        </p>
                        <p>
                            Outside of work, I am equally passionate
                            about fine art, drawing, and carpentry,
                            which continue to inspire my creative
                            process.
                        </p>
                    </Content>
                </ContentWrapper>
            </MainContentWrapper>
        </Wrapper>
    );
};

const mapStateToProps = state => ({
    themes: state.themes,
    current: state.currentTheme,
});

export default connect(mapStateToProps)(AboutMe);
